import React, { useState } from 'react';
import { TsdAPI } from '../../Api/tsdApi';
import { AiOutlineClose } from 'react-icons/ai';
import { LiaUserPlusSolid } from 'react-icons/lia';
import Popup from 'reactjs-popup';

const ModalButtonTsd = () => {
  const initialData = {
    username: '',
    password: '',
    role: 'User',
  };

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [allowedDeviceCount, setAllowedDeviceCount] = useState(3);
  const [bin, setBin] = useState();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData(initialData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const truncatedValue = value.slice(0, 25);
    setFormData({ ...formData, [name]: truncatedValue });
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const countInputChange = (e) => {
    setAllowedDeviceCount(e.target.value);
  };

  const binInputChange = (e) => {
    setBin(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.username.trim() === '' || formData.password.trim() === '') {
      alert('Поля "Название" и/или "Пароль" не могут быть пустыми');
      return;
    }

    try {
      const userExists = await TsdAPI.getIsExist(formData.username);
      if (userExists.some((user) => user.username === formData.username)) {
        alert('Пользователь с таким именем уже существует');
        return;
      }
    } catch (error) {
      console.error('Ошибка при проверке существования пользователя:', error);
      return;
    }

    if (!allowedDeviceCount) {
      return;
    }

    try {
      await TsdAPI.createUser(formData);
      console.log('Отправка данных:', formData);

      if (bin) {
        await TsdAPI.setBin({
          username: formData.username,
          bin: bin || '',
        });
      }

      await TsdAPI.patchUser({
        username: formData.username,
        devicecount: allowedDeviceCount,
      });

      closeModal();
    } catch (error) {
      console.error('Ошибка при создании пользователя:', error);
    }
  };

  return (
    <div>
      <Popup
        trigger={
          <button className="button_add ">
            <LiaUserPlusSolid className="btn_logo" />
          </button>
        }
        modal
      >
        {(close) => (
          <div className="modall addUser">
            <AiOutlineClose color="white" onClick={close} className="closeB" />
            <form className="new_user" onSubmit={handleSubmit}>
              <h4>Новый пользователь</h4>
              <label>Логин:</label>
              <input
                className="input-add-user password-input-username"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              <label>Пароль:</label>{' '}
              <input
                className="input-add-user password-input-general"
                type="text"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <label>Организация:</label>
              <input type="text" name="org" value={formData.org} onChange={InputChange} />
              <label>Кол-во девайсов:</label>
              <input type="number" name="qwd" value={allowedDeviceCount} onChange={countInputChange} />
              <label>БИН:</label>
              <input type="number" name="qwd" onChange={binInputChange} />
              <button type="submit">Сохранить</button>
            </form>
          </div>
        )}
      </Popup>

      {/* <button className="button_add" onClick={openModal}>
        <LiaUserPlusSolid className="btn_logo"/>
      </button>
      {isOpen && (
        <div className="modal-backdrop">
          <div className="modal">
            <div className="modal-content">
              <span className="modal-close" onClick={closeModal}>
                <AiOutlineClose style={{ fontSize: "30px" }} />
              </span>
              <form className="modal-form-add-user" onSubmit={handleSubmit}>
                
                <button
                  className="button-add-user modal-form-btn-add-user"
                  type="submit"
                >
                  Сохранить
                </button>
              </form>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ModalButtonTsd;
