import React, { useEffect, useState } from 'react';
import { TsdAPI } from '../../Api/tsdApi';
import { useParams, useNavigate } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
import { FiSave } from 'react-icons/fi';
import ChangePasswordModal from './ChangePassword';
import moment from 'moment';

import { updateUsername, deleteUsername, selectUser } from '../../services/slicers/UserSlice';
import { useSelector, useDispatch } from 'react-redux';
import { LiaReplySolid } from 'react-icons/lia';
import { FiUserX } from 'react-icons/fi';
import CustomTooltip from '../Hints/CustomTooltip';

const TSDLicenseDetail = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getFetchData();
  }, []);

  const getFetchData = () => {
    TsdAPI.getUserDetail({ username: username }).then((res) => {
      setData(res);
      dispatch(updateUsername(username));
    });
  };

  const handleDeleteUser = () => {
    try {
      const confirmed = window.confirm('Вы уверены что хотите удалить?');
      dispatch(deleteUsername);
      if (confirmed) {
        TsdAPI.deleteUser({ username: username }).then((res) => {
          navigate('/tsd');
        });
      }
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingBin, setIsEditingBin] = useState(false);
  const [isEditingDeviceCount, setIsEditingDeviceCount] = useState(false);
  const [isEditingExpireDate, setIsEditingExpireDate] = useState(false);

  const [editedOrg, setEditedOrg] = useState(data.org || '');
  const [editedBin, setEditedBin] = useState(data.bin || 0);

  const [editedDeviceCount, setEditedDeviceCount] = useState(data.availableDeviceCount || 0);
  const [editedExpireDate, setEditedExpireDate] = useState(data.expireDate || '');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditBinClick = () => {
    setIsEditingBin(true);
  };
  const handleEditDeviceCount = () => {
    setIsEditingDeviceCount(true);
  };
  const handleEditExpireDateClick = () => {
    setIsEditingExpireDate(true);
  };

  const setBin = async (username, editedBin) => {
    try {
      await TsdAPI.setBin({ username, bin: parseInt(editedBin) });
      console.log('setBin: Успешно установлен bin');
    } catch (error) {
      console.error('setBin: Ошибка при установке bin', error);
      throw error;
    }
  };

  const setOrg = async (username, editedOrg) => {
    try {
      await TsdAPI.setOrg({ username, org: editedOrg });
      console.log('setOrg: Успешно установлен Org');
    } catch (error) {
      console.error('setOrg: Ошибка при установке Org', error);
      throw error;
    }
  };
  const setExitedDate = async (username, editedExpireDate) => {
    try {
      await TsdAPI.setExpireDate({ username, expireDate: editedExpireDate });
      console.log('setExpireDate: Успешно установлен ExpireDate');
    } catch (error) {
      console.error('setExpireDate: Ошибка при установке ExpireDate', error);
      throw error;
    }
  };

  const patchUser = async (username, editedDeviceCount) => {
    try {
      await TsdAPI.patchUser({
        username,
        devicecount: parseInt(editedDeviceCount),
      });
      console.log('patchUser: Успешно обновлен devicecount');
    } catch (error) {
      console.error('patchUser: Ошибка при обновлении devicecount', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editedOrg) {
        await setOrg(data.username, editedOrg);
      }
      if (editedBin) {
        await setBin(data.username, editedBin);
      }

      if (editedDeviceCount) {
        await patchUser(data.username, editedDeviceCount);
      }

      if (editedExpireDate) {
        console.log(editedExpireDate);
        await setExitedDate(data.username, editedExpireDate);
      }

      getFetchData();

      setIsEditing(false);
      setIsEditingBin(false);
      setIsEditingDeviceCount(false);
      setIsEditingExpireDate(false);
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  const handleInputChange = (e) => {
    setEditedOrg(e.target.value);
    setData({ ...data, org: e.target.value });
  };

  const handleBinChange = (e) => {
    setEditedBin(e.target.value);
    setData({ ...data, bin: e.target.value });
  };

  const handleDeviceCountChange = (e) => {
    setEditedDeviceCount(e.target.value);
    setData({ ...data, availableDeviceCount: e.target.value });
  };

  const handleExpireDateChange = (e) => {
    setEditedExpireDate(e.target.value);
    setData({ ...data, expireDate: e.target.value });
  };

  const handleDeactivateLicense = async (username, isActive) => {
    try {
      let confurmed;
      if (isActive) {
        confurmed = window.confirm('Вы уверены, что хотите деактивировать лицензию?');
      } else {
        confurmed = window.confirm('Вы уверены, что хотите активировать лицензию?');
      }
      if (confurmed) {
        await TsdAPI.activeUser({ username });
        getFetchData();
      }
    } catch (error) {
      console.log('Error deactivate license:', error);
    }
  };

  return (
    <div className="licenseTable">
      <div className="table_option">
        <LiaReplySolid
          size={'1.5em'}
          onClick={() => {
            navigate('/tsd');
          }}
        />

        <div className="table_option_right">
          <button className="button_add delete_u" onClick={handleDeleteUser}>
            <FiUserX className="btn_logo delete_user" />
          </button>
          <CustomTooltip text="Изменение пароля">
            <ChangePasswordModal />
          </CustomTooltip>
        </div>
      </div>
      <div className="cardDetail">
        <h2>License Detail</h2>
        <hr />
        <div className="detail">
          <p>Username: {data.username ? data.username : '-'}</p>
          <p>Роль: {data.role ? data.role : '-'}</p>
          <p className="cardInfo">
            Организация:
            {isEditing ? (
              <input className="input_editable" type="text" value={data.org} onChange={handleInputChange} autoFocus />
            ) : (
              <span>{data.org || 'N/A'}</span>
            )}
            {isEditing ? (
              <button className="button_edit" onClick={handleSubmit}>
                <FiSave />
              </button>
            ) : (
              <button className="button_edit" onClick={handleEditClick}>
                <TbEdit />
              </button>
            )}
          </p>
          <p className="cardInfo">
            Доступное количество устройств:
            {isEditingDeviceCount ? (
              <input
                className="input_editable"
                type="number"
                value={data.availableDeviceCount}
                onChange={handleDeviceCountChange}
                autoFocus
              />
            ) : (
              <span>{data.availableDeviceCount || 'N/A'}</span>
            )}
            {isEditingDeviceCount ? (
              <button className="button_edit" onClick={handleSubmit}>
                <FiSave />
              </button>
            ) : (
              <button className="button_edit" onClick={handleEditDeviceCount}>
                <TbEdit />
              </button>
            )}
          </p>
          <p className="cardInfo">
            БИН:
            {isEditingBin ? (
              <input className="input_editable" type="text" value={data.bin} onChange={handleBinChange} autoFocus />
            ) : (
              <span>{data.bin || 'N/A'}</span>
            )}
            {isEditingBin ? (
              <button className="button_edit" onClick={handleSubmit}>
                <FiSave />
              </button>
            ) : (
              <button className="button_edit" onClick={handleEditBinClick}>
                <TbEdit />
              </button>
            )}
          </p>
          <p>Дата регистрации: {data.registerDate ? data.registerDate : '-'}</p>
          <p className="cardInfo">
            Дата окончания:
            {isEditingExpireDate ? (
              <input
                className="input_editable"
                type="date"
                value={data.expireDate}
                onChange={handleExpireDateChange}
                autoFocus
              />
            ) : (
              <span>
                {moment(data.expireDate).format('DD.MM.YYYY') === 'Invalid date'
                  ? data.expireDate
                  : moment(data.expireDate).format('DD.MM.YYYY') || 'N/A'}
              </span>
            )}
            {isEditingExpireDate ? (
              <button className="button_edit" onClick={handleSubmit}>
                <FiSave />
              </button>
            ) : (
              <button className="button_edit" onClick={handleEditExpireDateClick}>
                <TbEdit />
              </button>
            )}{' '}
          </p>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              width: '100%',
              gap: '10px',
            }}
          >
            Статус:
            <div
              className={`statusChangeBtn ${data.isActive ? 'ActiveSBtn' : 'DeactiveSBtn'}`}
              onClick={() => handleDeactivateLicense(data.username, data.isActive)}
            >
              {data.isActive ? 'Активирован' : 'Деактивирован'}
            </div>
          </p>
          {data.lastTransactionDate && <p>Last Transaction Date: {data.lastTransactionDate || 'N/A'}</p>}

          {data.rest ? (
            <>
              <hr />
              <h3>REST Information</h3>
              <p>Name: {data.rest?.name || ''}</p>
              <p>ID: {data.rest?.id || ''}</p>
              <p>URI: {data.rest?.uri || ''}</p>
            </>
          ) : null}

          <hr />
          {data.activeDevices?.length > 0 ? (
            <>
              <h2>Active Devices</h2>
              <hr />
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  borderSpacing: 0,
                }}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {data.activeDevices?.map((device, index) => (
                        <p key={index}>{device.name ? device.name : '-'}</p>
                      ))}
                    </td>

                    <td>
                      {data.activeDevices?.map((device, index) => (
                        <p key={index}>{device.id}</p>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TSDLicenseDetail;
