import React, { useState } from 'react';

const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </span>
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            top: '35px',
            left: '0',
            background: '#333',
            color: '#fff',
            padding: '5px',
            borderRadius: '5px',
            zIndex: '99999',
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
